exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-contentful-blog-post-slug-tsx": () => import("./../../../src/pages/blog/{contentfulBlogPost.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-contentful-blog-post-slug-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-calculators-air-capacitance-tsx": () => import("./../../../src/pages/calculators/air-capacitance.tsx" /* webpackChunkName: "component---src-pages-calculators-air-capacitance-tsx" */),
  "component---src-pages-calculators-hagen-poiseuille-tsx": () => import("./../../../src/pages/calculators/hagen-poiseuille.tsx" /* webpackChunkName: "component---src-pages-calculators-hagen-poiseuille-tsx" */),
  "component---src-pages-calculators-index-tsx": () => import("./../../../src/pages/calculators/index.tsx" /* webpackChunkName: "component---src-pages-calculators-index-tsx" */),
  "component---src-pages-calculators-serpentine-tsx": () => import("./../../../src/pages/calculators/serpentine.tsx" /* webpackChunkName: "component---src-pages-calculators-serpentine-tsx" */),
  "component---src-pages-calculators-volume-creep-tsx": () => import("./../../../src/pages/calculators/volume-creep.tsx" /* webpackChunkName: "component---src-pages-calculators-volume-creep-tsx" */),
  "component---src-pages-calculators-young-laplace-tsx": () => import("./../../../src/pages/calculators/young-laplace.tsx" /* webpackChunkName: "component---src-pages-calculators-young-laplace-tsx" */),
  "component---src-pages-calendar-confirmed-tsx": () => import("./../../../src/pages/calendar/confirmed.tsx" /* webpackChunkName: "component---src-pages-calendar-confirmed-tsx" */),
  "component---src-pages-calendar-index-tsx": () => import("./../../../src/pages/calendar/index.tsx" /* webpackChunkName: "component---src-pages-calendar-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-design-guide-tsx": () => import("./../../../src/pages/design-guide.tsx" /* webpackChunkName: "component---src-pages-design-guide-tsx" */),
  "component---src-pages-display-tsx": () => import("./../../../src/pages/display.tsx" /* webpackChunkName: "component---src-pages-display-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-form-tsx": () => import("./../../../src/pages/form.tsx" /* webpackChunkName: "component---src-pages-form-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invest-tsx": () => import("./../../../src/pages/invest.tsx" /* webpackChunkName: "component---src-pages-invest-tsx" */),
  "component---src-pages-marketplace-contact-tsx": () => import("./../../../src/pages/marketplace/contact.tsx" /* webpackChunkName: "component---src-pages-marketplace-contact-tsx" */),
  "component---src-pages-marketplace-index-tsx": () => import("./../../../src/pages/marketplace/index.tsx" /* webpackChunkName: "component---src-pages-marketplace-index-tsx" */),
  "component---src-pages-mvv-tsx": () => import("./../../../src/pages/mvv.tsx" /* webpackChunkName: "component---src-pages-mvv-tsx" */),
  "component---src-pages-partners-cmg-3271-a-48-d-5812-4-c-32-8963-e-98-bcc-42-b-591-index-tsx": () => import("./../../../src/pages/partners/cmg-3271a48d-5812-4c32-8963-e98bcc42b591/index.tsx" /* webpackChunkName: "component---src-pages-partners-cmg-3271-a-48-d-5812-4-c-32-8963-e-98-bcc-42-b-591-index-tsx" */),
  "component---src-pages-partners-controlled-fluidics-index-tsx": () => import("./../../../src/pages/partners/controlled-fluidics/index.tsx" /* webpackChunkName: "component---src-pages-partners-controlled-fluidics-index-tsx" */),
  "component---src-pages-partners-micromed-solutions-index-tsx": () => import("./../../../src/pages/partners/micromed-solutions/index.tsx" /* webpackChunkName: "component---src-pages-partners-micromed-solutions-index-tsx" */),
  "component---src-pages-partners-plastic-design-company-index-tsx": () => import("./../../../src/pages/partners/plastic-design-company/index.tsx" /* webpackChunkName: "component---src-pages-partners-plastic-design-company-index-tsx" */),
  "component---src-pages-partners-rapid-fluidics-index-tsx": () => import("./../../../src/pages/partners/rapid-fluidics/index.tsx" /* webpackChunkName: "component---src-pages-partners-rapid-fluidics-index-tsx" */),
  "component---src-pages-pricing-services-tsx": () => import("./../../../src/pages/pricing-services.tsx" /* webpackChunkName: "component---src-pages-pricing-services-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-resources-fluidics-design-checklist-tsx": () => import("./../../../src/pages/resources/fluidics-design-checklist.tsx" /* webpackChunkName: "component---src-pages-resources-fluidics-design-checklist-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-resources-thank-you-tsx": () => import("./../../../src/pages/resources/thank-you.tsx" /* webpackChunkName: "component---src-pages-resources-thank-you-tsx" */),
  "component---src-pages-roadmap-tsx": () => import("./../../../src/pages/roadmap.tsx" /* webpackChunkName: "component---src-pages-roadmap-tsx" */),
  "component---src-pages-science-tsx": () => import("./../../../src/pages/science.tsx" /* webpackChunkName: "component---src-pages-science-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-signedout-tsx": () => import("./../../../src/pages/signedout.tsx" /* webpackChunkName: "component---src-pages-signedout-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-walkthrough-tsx": () => import("./../../../src/pages/walkthrough.tsx" /* webpackChunkName: "component---src-pages-walkthrough-tsx" */),
  "component---src-pages-widgets-tsx": () => import("./../../../src/pages/widgets.tsx" /* webpackChunkName: "component---src-pages-widgets-tsx" */)
}

